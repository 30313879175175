import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import axios from 'axios';

import './HeaderMain.sass';
import { BASE_PATH, HEADER_SUPPORT } from '../../constants/constants';

const logo = require('../../common/images/logo.png');

interface IHeaderMain {
  extraClass?: string;
}

function HeaderMain({ extraClass }: IHeaderMain) {
  const cnHeaderMain = cn('HeaderMain');
  const [text, setText] = useState('');

  useEffect(() => {
    axios.get(`${BASE_PATH}${HEADER_SUPPORT}`).then((res) => {
      if (res.data) {
        setText(res.data);
      }
    });
  }, []);

  return (
    <div className={classnames(cnHeaderMain(), extraClass)}>
      <div className={classnames(cnHeaderMain('Wrapper'))}>
        <Link to="/" className={cnHeaderMain('Link')}>
          <img className={classnames(cnHeaderMain('Image'), 'Image')} src={logo} alt="VideoFun" />
        </Link>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={classnames(cnHeaderMain('Address'), 'Address')} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
}

HeaderMain.defaultProps = {
  extraClass: '',
};

export default HeaderMain;
