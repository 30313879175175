export const firebaseProjectToken = 'b5199681-b4ec-4756-893f-93ecfe520adf';

export const firebaseVapidKey = 'BOKT9fCl3d_nNwh4t63-uXPfVD2qsHGZiThSPyXnbLru68iz-jM8H0FrlE7nyfBM9Xa7-jTeeoLj0K3m9A-y444';

export const firebaseConfig = {
  apiKey: 'AIzaSyDsuQ21n3Go9aKzJcC7miu5TjKSYll4ZyU',
  authDomain: 'jfl-rb-life.firebaseapp.com',
  projectId: 'jfl-rb-life',
  storageBucket: 'jfl-rb-life.appspot.com',
  messagingSenderId: '542714482519',
  appId: '1:542714482519:web:2d826223b330c9165df812',
};
